@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "poppins";
  src: url("../public/fonts/Poppins.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "poppins";
  src: url("../public/fonts/PoppinsBold.woff2") format("woff2");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}